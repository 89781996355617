.radio {
  display: flex;
  align-items: center;
  label {
    font-family: "Lato", sans-serif;
    font-size: 18px;
    padding-left: 8px;
    cursor: pointer;
  }
  input[type="checkbox"] {
    width: 21px;
    height: 21px;
    background-color: #ffffff;
    border-radius: 14px;
    border: 1px solid rgb(164, 164, 164);
    appearance: none;
    -webkit-appearance: none;
    // outline: none;
    cursor: pointer;
    transition: 0.2s;
  }
  input[type="checkbox"]:checked {
    background: #5b98f5;
    background-color: #683ab7;
  }
}
