body {
  background-color: #f0ebf8;
}

.wrapper {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
