.results {
  margin-top: 15px;
  background-color: white;
  max-width: 360px;
  padding: 45px 35px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: "lato", sans-serif;
  h2 {
    font-weight: 500;
    letter-spacing: 1.5px;
  }
  h4 {
    font-weight: 500;
    font-size: 18px;
    letter-spacing: 1.5px;
    margin: 8px;
  }
  p {
    margin: 10px;
    font-weight: 500;
    font-size: 17px;
    letter-spacing: 0.8px;
    span {
      font-weight: 500;
      font-size: 18px;
      padding-left: 6px;
    }
  }
}
