.form {
  margin-top: 20px;
  padding: 24px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  // gap: 8px;
  margin-bottom: 10px;
  width: 280px;
  background-color: white;
  font-size: 18px;

  h3 {
    font-size: 24px;
    margin: 14px 0 0px;
    font-family: "Charter", sans-serif;
    text-align: center;
  }

  h4 {
    font-family: "Charter", sans-serif;
    font-weight: normal;
    margin: 10px 0 4px;
    text-align: center;
  }

  button {
    margin-top: 7px;
    color: white;
    background-color: #198754;
    background-color: #0d6efd;
    background-color: #683ab7;
    border: none;
    border-radius: 5px;
    width: 90px;
    height: 35px;
    font-size: 16px;
    letter-spacing: 0.6px;
    align-self: center;
  }
}
